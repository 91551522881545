import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loading from './JS/Loading';
import Navbar from './JS/Navbar';
import Pokemons from './JS/Pokemons';
import Contact from './JS/Contact';
import Home from './JS/Home';
import Pokemon from './JS/Pokemon';
import PokemonDetails from './JS/PokemonDetails';
import Footer from './JS/Footer';
import Documentation from './JS/Documentation';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<><Navbar /><Home /></>} />
          <Route path="/pokemons" element={<><Navbar /><Pokemons /></>} />
          <Route path="/contact" element={<><Navbar /><Contact /></>} />
          <Route path="/documentation" element={<><Documentation /><Contact/></>} />
          <Route path="/pokemon/:name" element={<PokemonDetails />} />                                 
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;