import '../CSS/home.css';
import { Link } from "react-router-dom";


function Home() {
    return (
        <div className="home-container">

            <div className="button-container">
                <Link to="/">Home</Link>
                <Link to="/pokemons">Pokemons</Link>
                <Link to="/contact">Contact</Link>
            </div>

            <div className="about-text">
                <p>
                    Welcome to PokePedia! Your one-stop destination for all things Pokémon. Explore a vast database of Pokémon, learn about their types, abilities, and evolutions. Whether you're a seasoned trainer or just starting your journey, PokePedia has everything you need to become a Pokémon master.
                </p>
            </div>
        </div>
    );
}

export default Home;
