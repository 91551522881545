import React from 'react';
import '../CSS/documentation.css';
import Footer from './Footer';

const Documentation = () => {
    return (
        <div className="documentation-container">
            <h1>Project Documentation</h1>
            
            <section>
                <h2>Introduction</h2>
                <p>Welcome to the PokePedia documentation. This page provides an overview of the code structure, the purpose of each component, and how to navigate through the website.</p>
            </section>

            <section>
                <h2>Home Component</h2>
                <p>The `Home` component serves as the main landing page for PokePedia. It contains navigation links to the main sections of the website and a brief introduction about the project.</p>
                <pre>
{`function Home() {
    return (
        <div className="home-container">
            <div className="button-container">
                <Link to="/">Home</Link>
                <Link to="/pokemons">Pokemons</Link>
                <Link to="/contact">Contact</Link>
            </div>
            <div className="about-text">
                <p>
                    Welcome to PokePedia! Your one-stop destination for all things Pokémon. Explore a vast database of Pokémon, learn about their types, abilities, and evolutions.
                </p>
            </div>
        </div>
    );
}`}
                </pre>
                <p>This component displays navigation buttons using `react-router-dom`'s `Link` component to route users to different pages, and includes a brief welcome message for users visiting the site.</p>
            </section>

            <section>
                <h2>Loading Component</h2>
                <p>The `Loading` component provides a simple loading animation that is displayed while the website is fetching data or processing requests.</p>
                <pre>
{`function Loading() {
    return (
        <div className="loading">
            <div className="loading-circle"></div>
        </div>
    )
}`}
                </pre>
                <p>This component renders a circular loading indicator that can be styled through CSS for visual feedback to users during loading states.</p>
            </section>

            <section>
                <h2>Pokemon Component</h2>
                <p>The `Pokemon` component is responsible for rendering individual Pokémon data, including their name, image, and a link to their detailed view.</p>
                <pre>
{`function Pokemon({ pokemon }) {
    return (
        <div className="">
            <Link to={\`/pokemon-details/\${pokemon.order}\`}><h2>{pokemon.name}</h2></Link>
            <img src={pokemon.url}></img>
            <p>{pokemon.front_shiny}</p>
        </div>
    )
}`}
                </pre>
                <p>This component displays a Pokémon's name as a clickable link to its detailed page, alongside an image and some basic information.</p>
            </section>

            <section>
                <h2>Pokemons Component</h2>
                <p>The `Pokemons` component is responsible for fetching and displaying a list of Pokémon. It includes search functionality, type filtering, pagination, and dynamically loaded Pokémon data.</p>
                <pre>
{`function Pokemons() {
    const [pokemons, setPokemons] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPokemons, setTotalPokemons] = useState(0);
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState("");

    useEffect(() => {
        fetchData();
        fetchTypes();
    }, [currentPage]);

    const fetchData = async (term = "", type = "") => {
        // Fetching data logic here
    };

    const fetchTypes = async () => {
        // Fetching Pokémon types logic here
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleTypeFilter = (type) => {
        setSelectedType(type);
        fetchData("", type);
    };

    return (
        <div className="pokemon-container">
            {isLoading && <Loading />}
            <div className="search-section">
                <input 
                    type="text" 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search for a Pokémon"
                />
                <button onClick={handleSearch}>Search</button>
            </div>

            <div className="filter-section">
                <button>Filter by Type</button>
                <ul id="type-list" className="type-list">
                    {types.map((type, index) => (
                        <li key={index} onClick={() => handleTypeFilter(type.name)}>
                            {type.name}
                        </li>
                    ))}
                </ul>
            </div>

            <div className="pokemon-table">
                {pokemons.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Pokédex ID</th>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Types</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pokemons.map((pokemon, index) => (
                                <tr key={index}>
                                    <td>{pokemon.id}</td>
                                    <td><Link to={\`/pokemon/\${pokemon.name}\`}>{pokemon.name}</Link></td>
                                    <td><img src={pokemon.sprites?.front_default} alt={pokemon.name} /></td>
                                    <td>{pokemon.types ? pokemon.types.map(typeInfo => typeInfo.type.name).join(', ') : "No types available"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No pokemons found.</p>
                )}
            </div>

            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button key={index} onClick={() => handlePageChange(index + 1)}>
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
}`}
                </pre>
                <p>This component handles searching, filtering, and pagination for Pokémon, while also fetching the Pokémon list and types from an API.</p>
            </section>

            <section>
                <h2>PokemonDetails Component</h2>
                <p>The `PokemonDetails` component fetches detailed information for a specific Pokémon and displays it. It is triggered when the user clicks on a Pokémon's name in the list.</p>
                <pre>
{`function PokemonDetails() {
    const { name } = useParams();
    const [pokemon, setPokemon] = useState(null);

    useEffect(() => {
        fetchPokemonData();
    }, []);

    const fetchPokemonData = async () => {
        try {
            const response = await fetch(\`https://pokeapi.co/api/v2/pokemon/\${name}\`);
            const data = await response.json();
            setPokemon(data);
        } catch (error) {
            console.error("Error fetching Pokémon data:", error);
        }
    };

    if (!pokemon) {
        return <p>Loading...</p>;
    }

    return (
        <div className="pokemon-details-container">
            <div className="pokemon-image-section">
                <img 
                    src={pokemon.sprites.other['official-artwork'].front_default} 
                    alt={pokemon.name} 
                />
            </div>
            <div className="pokemon-info-section">
                <h1>#{pokemon.id.toString().padStart(3, '0')} {pokemon.name.toUpperCase()}</h1>
                <p>{pokemon.name} is a Pokémon that...</p>
                <button>LEARN MORE</button>
            </div>
        </div>
    );
}`}
                </pre>
                <p>This component renders the detailed view of a Pokémon, showing its image, name, and other information retrieved from the PokeAPI.</p>
            </section>

            <section>
                <h2>Navbar Component</h2>
                <p>The `Navbar` component creates a navigation bar with a welcome message. It helps users navigate through the main sections of the website.</p>
                <pre>
{`function Navbar() {
    return (
        <nav style={{ display: 'flex', backgroundColor: '#011840', alignItems: "center" }}>
            <div>
                <h1 style={{ marginTop: '3vh' }}>Welcome to PokePedia</h1>
            </div>
        </nav>
    );
}`}
                </pre>
                <p>The `nav` element is styled with a centered heading. It provides an intuitive navigation layout for users.</p>
            </section>

            <section>
                <h2>Footer Component</h2>
                <p>The `Footer` component displays the contact information and links to important pages. It is styled with flexbox for responsiveness.</p>
                <pre>
                </pre>
                <p>The `Footer` provides important site navigation links and company or copyright information.</p>
                <p>Done by Łukasz Pawlik</p>
            </section>

            <Footer />
        </div>
    );
};

export default Documentation;
