import React from 'react';
import '../CSS/Footer.css';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <section className="contact-section">
                <div className="contact-info">
                    <h3>Contact Us</h3>
                    <p>We'd love to hear from you! Feel free to reach out via any of the methods below.</p>
                    <p>Address: 123 PokePedia Lane</p>
                    <p>Email: support@pokepedia.com</p>
                </div>
                <div className="contact-links">
                    <h3>Useful Links</h3>
                    <ul>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/support">Support</a></li>
                        <li><Link to={`/documentation`}>Documentation</Link></li>
                        <li><a href="/">Back</a></li>
                    </ul>
                </div>
            </section>
        </footer>
    );
};

export default Footer;

