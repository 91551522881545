import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "./Loading";
import "../CSS/pokemons.css";

const POKEMON_LIMIT = 100;

function Pokemons() {
    const [pokemons, setPokemons] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPokemons, setTotalPokemons] = useState(0);
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState("");

    useEffect(() => {
        fetchData();
        fetchTypes();
    }, [currentPage]);

    const handleSearch = () => {
        if (searchTerm.trim()) {
            fetchData(searchTerm.toLowerCase());
        }
    };

    const fetchData = async (term = "", type = "") => {
        setIsLoading(true);
        try {
            let url;
            if (term) {
                url = `https://pokeapi.co/api/v2/pokemon/${term}`;
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const result = await response.json();
                setPokemons([result]);
                setTotalPokemons(1);
            } else if (type) {
                url = `https://pokeapi.co/api/v2/type/${type}`;
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const result = await response.json();
                const filteredPokemons = await Promise.all(
                    result.pokemon.map(async (p) => {
                        const pokemonResponse = await fetch(p.pokemon.url);
                        return await pokemonResponse.json();
                    })
                );
                setPokemons(filteredPokemons);
                setTotalPokemons(filteredPokemons.length);
            } else {
                url = `https://pokeapi.co/api/v2/pokemon?limit=${POKEMON_LIMIT}&offset=${(currentPage - 1) * POKEMON_LIMIT}`;
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const result = await response.json();
                const pokemonData = await Promise.all(
                    result.results.map(async (pokemon) => {
                        const pokemonResponse = await fetch(pokemon.url);
                        return await pokemonResponse.json();
                    })
                );
                setPokemons(pokemonData);
                setTotalPokemons(result.count);
            }
        } catch (error) {
            console.error("Error occurred:", error);
            setPokemons([]);
            setTotalPokemons(0);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTypes = async () => {
        try {
            const response = await fetch('https://pokeapi.co/api/v2/type');
            if (!response.ok) {
                throw new Error("Failed to fetch types");
            }
            const result = await response.json();
            setTypes(result.results);
        } catch (error) {
            console.error("Error occurred:", error);
            setTypes([]);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleTypeFilter = (type) => {
        setSelectedType(type);
        fetchData("", type);
    };

    const totalPages = Math.ceil(totalPokemons / POKEMON_LIMIT);

    return (
        <div className="pokemon-container">
            {isLoading && <Loading />}
            <div className="search-section">
                <input 
                    type="text" 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search for a Pokémon"
                />
                <button onClick={handleSearch}>Search</button>
            </div>

            <div className="filter-section">
                <button onClick={() => document.getElementById('type-list').classList.toggle('visible')}>
                    Filter by Type
                </button>
                <ul id="type-list" className="type-list">
                    {types.map((type, index) => (
                        <li key={index} onClick={() => handleTypeFilter(type.name)}>
                            {type.name}
                        </li>
                    ))}
                </ul>
            </div>

            <div className="pokemon-table">
                {pokemons.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: '50px' }}>Pokédex ID</th>
                                <th style={{ width: '100px' }}>Name</th>
                                <th style={{ width: '120px' }}>Image</th>
                                <th style={{ width: '100px' }}>Types</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pokemons.map((pokemon, index) => (
                                <tr key={index}>
                                    <td>{pokemon.id}</td>
                                    <td>
                                        <div className="nazwa">
                                        <Link to={`/pokemon/${pokemon.name}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            {pokemon.name}
                                        </Link>
                                        </div>
                                    </td>
                                    <td>
                                        <img 
                                            src={pokemon.sprites?.front_default} 
                                            alt={pokemon.name} 
                                            style={{ width: '150px', height: 'auto' }}
                                        />
                                    </td>
                                    <td>
                                        {pokemon.types 
                                            ? pokemon.types.map(typeInfo => typeInfo.type.name).join(', ')
                                            : "No types available"
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No pokemons found.</p>
                )}
            </div>

            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button 
                        key={index + 1} 
                        onClick={() => handlePageChange(index + 1)}
                        disabled={index + 1 === currentPage}
                        style={{ margin: '0 5px' }}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default Pokemons;
