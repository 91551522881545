import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import '../CSS/pokemondetails.css';
import { Link } from "react-router-dom";

function PokemonDetails() {
    const { name } = useParams();
    const [pokemon, setPokemon] = useState(null);

    useEffect(() => {
        fetchPokemonData();
    }, []);

    const fetchPokemonData = async () => {
        try {
            const response = await fetch(`https://pokeapi.co/api/v2/pokemon/${name}`);
            const data = await response.json();
            setPokemon(data);
        } catch (error) {
            console.error("Error fetching Pokémon data:", error);
        }
    };

    if (!pokemon) {
        return <p>Loading...</p>;
    }

    return (
        <div className="pokemon-details-container">
            <div className="triangle"></div>
            <div className="turbozdjecie">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/International_Pok%C3%A9mon_logo.svg/269px-International_Pok%C3%A9mon_logo.svg.png" alt="Pokemon Logo" style={{ height: '11vh', marginLeft: '-10vh', marginTop: '0vh' }} />  
            </div>
            <div className="pokemon-image-section">
                <img 
                    src={pokemon.sprites.other['official-artwork'].front_default} 
                    alt={pokemon.name} 
                    className="pokemon-image"
                />
            </div>
            <div className="divider"></div>
            <div className="pokemon-info-section">
                <h1 className="pokemon-name">
                    #{pokemon.id.toString().padStart(3, '0')}
                    <div className="pokemon-name2">
                        {pokemon.name.toUpperCase()}
                    </div>
                </h1>
                <p className="pokemon-description">
                    {pokemon.name.charAt(0).toUpperCase() + pokemon.name.slice(1)} are small, squat amphibian and plant Pokémon 
                    that move on all four legs, and have blue-green bodies with darker blue-green spots.
                </p>
                <button className="learn-more-button">LEARN MORE</button>
                <div className="pokemon-evolutions">
                    <h3>Evolutions:</h3>
                    <div className="evolution-icons">
                        {pokemon.types.map((typeInfo, index) => (
                            <img 
                                key={index}
                                src={pokemon.sprites.front_default} 
                                alt={`${pokemon.name}-evolution`} 
                                className="evolution-image"
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PokemonDetails;
