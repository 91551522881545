import { Link } from "react-router-dom";
import '../CSS/contact.css';

function Navbar() {
    return (
        <nav>
            <div className="header-section">
                <h1>Welcome to PokePedia</h1>
            </div>
        </nav>
    );
}

export default Navbar;
