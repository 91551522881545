import {Link} from "react-router-dom"
import '../CSS/contact.css';
import Footer from "./Footer";

function Contact() {
    return (
        <div>
            <h1>Contact</h1>
            <Footer></Footer>
        </div>
    )
}

export default Contact;