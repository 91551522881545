import '../CSS/loading.css';
function Loading() {
    return (
        <div className="loading">
            <div className="loading-circle">

            </div>
        </div>
    )
}

export default Loading